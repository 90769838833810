export enum PropietaryEnum {
  JORGE = 'JORGE',
  GABRIEL = "GABRIEL",
  HA = "HA"
}

export enum VisibilityEnum {
  INVISIBLE,
  ViSIBLE
}
