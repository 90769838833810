import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './pages/intro/intro.component';
import { MasterGalleryComponent } from './pages/master-gallery/master-gallery.component';
import { DetailsGalleryComponent } from './pages/details-gallery/details-gallery.component';
import { StudioComponent } from './pages/studio/studio.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardDetailsComponent } from './pages/dashboard-details/dashboard-details.component';
import { UserGuard } from './user.guard';




const routes: Routes = [
  {path:"", redirectTo: "/", pathMatch: 'full'},
  {path:"", component: IntroComponent},
  {path:"gallery", component: MasterGalleryComponent},
  {path:"jorge/gallery", component: MasterGalleryComponent},
  {path:"gabriel/gallery", component: MasterGalleryComponent},
  {path:"ha/gallery", component: MasterGalleryComponent},
  {path:"gallery/:id", component: DetailsGalleryComponent},
  {path:"jorge/gallery/:id", component: DetailsGalleryComponent},
  {path:"gabriel/gallery/:id", component: DetailsGalleryComponent},
  {path:"ha/gallery/:id", component: DetailsGalleryComponent},
  {path:"estudio", component: StudioComponent},
  {path:"admin/login", component: LoginComponent},
  {path:"admin/dashboard", component: DashboardComponent, canActivate:[UserGuard]},
  {path:"admin/dashboard/add", component: DashboardDetailsComponent, canActivate:[UserGuard]},
  {path:"admin/dashboard/edit/:id", component: DashboardDetailsComponent, canActivate:[UserGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
