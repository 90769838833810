<div *ngIf="showContent" class="container login-container">
  <div class="flex-contain d-print-table-row">
    <div class="item-container logo">
      <h1 class="title">Henríquez Arquitectos</h1>
    </div>
    <div class=" item-container offset-2 col-lg-8">
      <input placeholder="Correo" type="text" pInputText [(ngModel)]="user.email"/>
    </div>
    <div class=" item-container offset-2 col-lg-8">
      <input placeholder="Contraseña" type="password" pPassword [(ngModel)]="pass"/>
      <span style="color: red;" *ngIf="errors">{{errors.message}}</span>
    </div>
    <button class="item-container col-lg-4 btn-login" (click)="onLogin(user, pass)" type="button">Iniciar</button>

  </div>
</div>
