<a class="link-home" routerLink="/">
  <h1 class="title">Henríquez Arquitectos</h1>
</a>
<ul class="nav-bar">
  <li class="item-list">
    <a class="item-click" routerLink="/gallery" routerLinkActive="active-menu">{{mode == 'es'? '+Proyectos' : '+Projects'}}</a>
  </li>
  <li *ngIf="!hide" class="item-list">
    <a class="item-click" routerLink="/ha/gallery" routerLinkActive="active-menu">-Henríquez Arquitectos</a>
  </li>
  <li *ngIf="!hide" class="item-list">
    <a class="item-click" routerLink="/gabriel/gallery" routerLinkActive="active-menu">-Henríquez, Gabriel</a>
  </li>
  <li *ngIf="!hide" class="item-list">
    <a class="item-click " routerLink="/jorge/gallery" routerLinkActive="active-menu">-Henríquez,
      Jorge</a>
  </li>
  <li class="item-list"><a class="item-click" routerLink="/estudio" routerLinkActive="active-menu">{{mode == 'es' ?'+Estudio': '+Office'}}</a>
  </li>
</ul>
