import { Component, OnInit } from '@angular/core';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-pages',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss']
})
export class StudioComponent implements OnInit {
  urlStudioImg: string;

  constructor(private galleryService: GalleryService) { }

  ngOnInit(): void {
    this.galleryService.getImgLanding('studio').then(url => this.urlStudioImg = url).catch(console.error)
  }

}
