import { Injectable, ɵConsole } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Gallery } from '../schemas/models/gallery.model';
import { take, finalize, tap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { readDataFromStorage } from '../pages/master-gallery/master-gallery.component';



@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  title = "cloudsSorage";
  selectedFile: File = null;
  fb;
  mode: 'es'| 'en' = 'es';

  downloadURL: Observable<string>;

  dictionary = {
    ['casa_abreu']: "Casa Abreu",
    ['casa_bancal']: "Casa Bancal",
    ['girasol']: "Girasol",
    ['hotel_escala']: "Hotel Escala",
    ['parque_maspalomas']: "Maspalomas",
    ['porcelanosa']: "Porcelanosa",
    ['roma']: "C dello Studente",
    ['calp']: 'IAC',
    ['casa_palmert']: "Casa Palmert",
    ['club_nautico']: "RCN La Palma",
    ['cst']: "Centro de Salud",
    ['iglesia_benahoare']: "Iglesia La Palma",
    ['oficina_turismo']: "Oficina de turismo",
    ['polideportivo']: "Centro Multiusos",
    ['vivienda_llorens']: "Casa Llorens",
    ['casa_yanes']: "Casa Yanes",
    ['casa_brezal']: "Casa El Brezal",
    ['hotel_terapeutico']: "Hotel Terapéutico",
    ['hsj']: "Cancajos",
    ['marquesina_HSJ']: "Ampliación HSJ",
    ['oficina_policia']: "Oficina de policia",
    ['parque_tecnologico']: "C. Tecnológico",
    ['piscina_municipal']: "Piscina Municipal",
    ['villa_levy']: "Villas Levy",
    ['vivienda_andres']: "Casa Andrés",
    ['vivienda_lorenzo']: "Casa Lorenzo",
    ['arsenal']: "Arsenal",
    ['asieta']: 'Asieta',
    ['bodega_tijarafe']: "Bodega El Cedro"
  }

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }

  getAllProyects(): Promise<Gallery[]> {
    return this.db.list<Gallery>(`gallery`).valueChanges().pipe(take(1)).toPromise();
  }

  getProyectByID(id: string): Promise<Gallery> {
    return this.db.object<Gallery>(`gallery/${id}`).valueChanges().pipe(take(1)).toPromise();
  }

  getProyectsFiltered(propietary: string): Promise<Gallery[]> {
    return this.db.list<Gallery>(`gallery`, ref => ref.orderByChild('propietary').equalTo(propietary.toUpperCase())).valueChanges().pipe(take(1)).toPromise();
  }

  setProyect(proyect: Gallery): Promise<void> {
    if (!proyect.id) {
      proyect.id = this.db.createPushId();
    }
    return this.db.object<Gallery>(`gallery/${proyect.id}`).set(proyect);
  }

  deteleProyect(id: string): Promise<void> {
    return this.db.object<Gallery>(`gallery/${id}`).remove();
  }

  uploadImg(file: File, nameFolder: string, fileName: Number): Promise<string> {
    const filePath = `${nameFolder}/${fileName}`;
    const fileRef = this.storage.ref(filePath);

    return new Promise((resolve) => {
      this.storage.upload(`${filePath}`, file)
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(url => {
              if (url) {
                this.fb = url;
                resolve(url)
              }
              console.log(this.fb);
            });
          })
        ).subscribe()
    })

  }

  async getRefFromPhotos(propietary: 'JORGE' | 'GABRIEL' | 'HA', proyectsArray: string[]): Promise<readDataFromStorage> {

    let proyectsStorage: readDataFromStorage = {
      propietary,
      proyects: [
        {
          proyectName: '',
          urls: []
        }
      ]
    }
    //Tenemos que borrar al primera posicion, dado que no me deja declarar array vacio.
    proyectsStorage.proyects.shift();

    for (const proyect of proyectsArray) {

      const ref = this.storage.ref(`${propietary}/proyectos/${proyect}`);
      const reference = await ref.listAll().pipe(take(1)).toPromise();

      const pathName = reference.items.map((ref: any) => {
        const arrayPath = ref.location.path.split('/');
        return arrayPath[arrayPath.length - 1]
      })

      // Hasta aqui ["Arsenal_01_Jorge.jpg", "Arsenal_02_Jorge.jpg"]
      const promises = pathName.map(path => ref.child(path).getDownloadURL().pipe(take(1)).toPromise())


      const urls = await Promise.all(promises);

      proyectsStorage.proyects.push({
        proyectName: proyect,
        urls
      })
    }


    return proyectsStorage;
  }

  async setImagesByPropietary(setImages: readDataFromStorage[]) {

    const proyects = await this.getProyectsFiltered('HA');
    const setImagesBy = setImages.find(name => name.propietary == 'HA').proyects;

    const updatedData = proyects.map(proyect => {
      for (const p of setImagesBy) {
        if (proyect.coverName == this.dictionary[p.proyectName]) {
          return {
            ...proyect,
            urlImgs: p.urls
          } as Gallery
        }
      }
    })
    console.log(updatedData);

    const promises = [];

    updatedData.forEach(g => promises.push(this.setProyect(g)));

    Promise.all(promises).then(response => console.log("ACTUALIZADA")).catch(console.error)


  }

  downloadImgFromUrl(url: string): Promise<Blob> {
    // `url` is the download URL for 'images/stars.jpg'
    return new Promise((resolve => {
      // This can be downloaded directly:
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function (event) {
        var blob: Blob = xhr.response;
        resolve(blob);
      };
      xhr.open('GET', url);
      xhr.send();
    }))

  }

  setImgLanding(url: string, folder: 'portrait' |'studio' ): Promise<void> {
    return this.db.object<string>(`${folder}`).set(url);
  }

  getImgLanding(folder: 'portrait' |'studio' ): Promise<string> {
    return this.db.object<string>(`${folder}`).valueChanges().pipe(take(1)).toPromise();
  }


  setMode(mode: 'es' | 'en') {
    this.mode = mode;
  }

  getMode() : 'es'| 'en' {
    return this.mode;
  }

}
