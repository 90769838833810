import { Component, OnInit } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { GalleryService } from 'src/app/services/gallery.service';
import { Gallery } from 'src/app/schemas/models/gallery.model';

@Component({
  selector: 'app-details-gallery',
  templateUrl: './details-gallery.component.html',
  styleUrls: ['./details-gallery.component.scss']
})
export class DetailsGalleryComponent implements OnInit {

  public gallery: Gallery;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gallerySrv: GalleryService) { }

  async ngOnInit(): Promise<void> {
    const id = (await this.route.paramMap.pipe(take(1)).toPromise()).get('id');
    this.gallery = await this.gallerySrv.getProyectByID(id);
  }

}
