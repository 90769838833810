import { Component, OnInit } from '@angular/core';
import { GalleryService } from 'src/app/services/gallery.service';
import { Gallery } from 'src/app/schemas/models/gallery.model';
import { Router, Route } from '@angular/router';
import { PropietaryEnum } from 'src/app/schemas/enums/gallery.enum';

export interface readDataFromStorage {
  propietary: 'GABRIEL' | 'JORGE' | 'HA',
  proyects?: [
    {
      proyectName: string,
      urls: string[]
    }
  ]
}

@Component({
  selector: 'app-master-gallery',
  templateUrl: './master-gallery.component.html',
  styleUrls: ['./master-gallery.component.scss']
})
export class MasterGalleryComponent implements OnInit {
  currentUrl: string = this.router.url;
  proyects: Gallery[];
  pagination: Gallery[][] = [];
  page: number = 0;
  emptySquares = [];

  constructor(private gallerySrv: GalleryService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    const arrayUrl: string[] = this.currentUrl.split('/');
    if (arrayUrl.length > 2) {
      this.proyects = await this.gallerySrv.getProyectsFiltered(arrayUrl[1].toUpperCase())
    } else {
      this.proyects = await this.gallerySrv.getAllProyects();
      //this.proyects = [...this.proyects, ...this.proyects, ...this.proyects, ...this.proyects]
    }
    this.setEmptySquares(this.proyects.length, 6);

    if(this.proyects.length > 30) {
      let proy = [];
      let count = 0;
      this.proyects.forEach((p, idx) => {
        if (count < 29) {
          count++;
          proy.push(p)
          if (idx == this.proyects.length - 1 && proy.length) {
            this.pagination.push(proy)
          }
        } else {
          proy.push(p)
          this.pagination.push(proy)
          proy = [];
          count = 0;
        }
      })
    } else {
      this.pagination.push(this.proyects)
    }

  }

  setEmptySquares(maxNumber : number, divide: number) {
    let resto = maxNumber / divide;
    let leftSquares = divide - resto;
    console.log(leftSquares);
    for (let i = 0; i < leftSquares; i++) {
      this.emptySquares.push(1)
    }
  }

  goToDetails(id: string) {
    this.router.navigateByUrl(`${this.currentUrl}/${id}`);
  }

  nextPage(page) {
    this.page++

  }
  backPage(page) {
    this.page--
  }

}
