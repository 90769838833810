import { Component, OnInit } from '@angular/core';
import { Gallery } from 'src/app/schemas/models/gallery.model';
import { PropietaryEnum } from 'src/app/schemas/enums/gallery.enum';
import { GalleryService } from 'src/app/services/gallery.service';
import { take } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { Location } from '@angular/common';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import swal from 'sweetalert2';




@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit {

  loading: boolean = false;

  proyect: Gallery = {
    id: "",
    name: "",
    propietary: PropietaryEnum.JORGE,
    location: "",
    year: "",
    urlCover: "",
    coverName: "",
    urlImgs: [],
    isVisible: false,
    order: null,
    orderPropietary: null,
    isBlacked: false
  };

  mode: 'edit' | 'add' = 'add';

  filePreviousUpload: File[] = [];

  propietary: { name: string, propietary: "GABRIEL" | "JORGE" | "HA" }[] = [
    { name: 'Gabriel', propietary: 'GABRIEL' },
    { name: 'Jorge', propietary: 'JORGE' },
    { name: 'Ha', propietary: 'HA' },
  ];

  fileCover: File[] = [];
  uploadedFiles: File[] = [];
  propietarySelected: { name: string, propietary: "GABRIEL" | "JORGE" | "HA" } = { name: 'Jorge', propietary: "JORGE" };
  authors: string[];
  awards: string[];

  constructor(private gallerySvc: GalleryService, private router: Router, private route: ActivatedRoute, private utilsSvc: UtilsService, private location: Location) { }


  async ngOnInit(): Promise<void> {
    if (this.router.url.includes('edit')) {
      this.mode = 'edit';
      const id = (await this.route.paramMap.pipe(take(1)).toPromise()).get('id');
      this.proyect = await this.gallerySvc.getProyectByID(id);

      const blobUrlCover: Blob = await this.gallerySvc.downloadImgFromUrl(this.proyect.urlCover);
      const file: File = new File([blobUrlCover], `${this.proyect.urlCover}.jpg`, { type: 'image/*', lastModified: Date.now() })
      this.fileCover = [file];

      for (const url of this.proyect.urlImgs) {
        const getImage: Blob = await this.gallerySvc.downloadImgFromUrl(url);
        const file: File = new File([getImage], `${url}.jpg`, { type: 'image/*', lastModified: Date.now() });
        this.uploadedFiles = [...this.uploadedFiles, file];
      }
    }
  }

  onBasicUpload(event) {
    console.log(event);
    this.fileCover = event.files as File[];
  }

  async onAddProyect() {
    this.loading = true;
    this.proyect.propietary = this.propietarySelected.propietary;

    this.proyect.urlCover = await this.gallerySvc.uploadImg(this.fileCover[0], `PEDRO`, Date.now() + Math.random());

    //Añadimos todas las imagenes
    //${this.proyect.propietary.toUpperCase()}

    const promises = this.uploadedFiles.map(file => this.gallerySvc.uploadImg(file, `PEDRO/proyectos`, Date.now() + Math.random()));
    const urls: string[] = await Promise.all(promises);

    this.proyect.urlImgs = [...urls];

    //Añadimos la imgCover

    this.gallerySvc.setProyect(this.proyect).then(response => {
      this.loading = false;
      swal.fire({
        position: 'center',
        icon: 'success',
        title: `El proyecto se ha ${this.router.url.includes('edit')?'modificado': 'guardado'} correctamente`,
        showConfirmButton: false,
        timer: 1500
      })
      this.location.back();
    }).catch(console.error)


  }

  removeCover(event) {
    this.fileCover = [];
  }

  myUploader(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    console.log(this.uploadedFiles);
  }

  onFileQueueRemove(event) {
    console.log(this.uploadedFiles);
    this.uploadedFiles = this.uploadedFiles.filter(f => f.name !== event.file.name);
    console.log(this.uploadedFiles);
  }

  goBack() {
    this.location.back();
  }

  async deleteProyect(id: string) {
    swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez aceptes, no podrás volver atrás.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar'
    }).then((result) => {
      if (result.value && id) {
        this.gallerySvc.deteleProyect(id);
        swal.fire(
          'Borrado!',
          'El proyecto se ha borrado satisfactoriamente',
          'success'
        )
        this.location.back();
      }
    })



  }

  focusChipsOut(event) {
    console.log(event);

  }
}
