import { Component, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { GalleryService } from 'src/app/services/gallery.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  portraitImgUrl: string;

  constructor(private galleryService: GalleryService, private router : Router) { }

  ngOnInit() {
    this.galleryService.getImgLanding('portrait').then(url => this.portraitImgUrl = url).catch(console.error);
  }

  goToGallery(mode : 'es' | 'en') {
    this.galleryService.setMode(mode);
    this.router.navigateByUrl('/gallery');
  }

}
