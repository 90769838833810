<p-blockUI [blocked]="loading" [target]="pnl">
  <div style="position:absolute; width: 110px; left: 47.5%; top:50%">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>
<div #pnl class="container" >
  <div class="btn-container">
    <div class="back btn" [hidden]="loading" (click)="goBack()">
      <span> <img class="" src="/assets/icons/next.svg" alt=""> Atrás</span>
    </div>
    <div class="delete btn" (click)="deleteProyect(proyect.id)">
      <span>Eliminar</span>
    </div>
  </div>
  <div class="main-data">
    <div class="item-container">
      <label for="">Añade foto principal</label>
      <p-fileUpload [hidden]="loading" [files]="fileCover" previewWidth="100" chooseLabel="Caratula" [auto]="true"
        accept="image/*" (uploadHandler)="onBasicUpload($event)" customUpload="true" (onRemove)="removeCover($event)">
      </p-fileUpload>
    </div>
    <div class="mode-greyScale">
      <label for="">Activar modo escala de grises</label>
      <p-inputSwitch [disabled]="loading" [(ngModel)]="proyect.isBlacked"></p-inputSwitch>
    </div>

    <div class="form">
      <div>
        <div class="item-container ui-float-label">
          <input type="text" id="coverName" pInputText [(ngModel)]="proyect.coverName"/>
          <label for="coverName">Nombre exterior</label>
        </div>

        <div class="item-container ui-float-label">
          <input inputId="name"  [(ngModel)]="proyect.name" type="text" pInputText />
          <label for="name">Nombre interior</label>
        </div>

        <div style="display: flex; flex-direction: column-reverse;" class="item-container">
          <p-inputNumber id="order"  [(ngModel)]="proyect.order"></p-inputNumber>
          <label for="order">Orden general</label>
        </div>
        <div style="display: flex; flex-direction: column-reverse;" class="item-container">
          <p-inputNumber id="orderPropietary"  [(ngModel)]="proyect.orderPropietary"></p-inputNumber>
          <label for="orderPropietary">Orden por propietario</label>
        </div>
        <div  class="item-container ui-float-label">
          <input inputId="location" [(ngModel)]="proyect.location" type="text" pInputText />
          <label for="location">Localización</label>
        </div>
      </div>
      <div>
        <div class="item-container ui-float-label">
          <input inputId="year" type="text" [(ngModel)]="proyect.year" pInputText />
          <label for="year">Año del proyecto</label>
        </div>

        <div class="item-container">
          <p-dropdown [disabled]="loading" [options]="propietary" [(ngModel)]="propietarySelected"
             optionLabel="name" [showClear]="true">
          </p-dropdown>
        </div>

        <div class="item-container ui-float-label">
          <p-chips inputId="authors" [(ngModel)]="proyect.authors"></p-chips>
          <label for="authors">Autores</label>
        </div>

        <div class="item-container">
          <p-chips inputId="awards" placeholder="Premios" [(ngModel)]="proyect.awards"></p-chips>
          <!-- <label for="awards">Premios</label> -->
        </div>
        <div class="item-container ui-float-label">
          <textarea inputId="descriptions" style ="margin-top: -16px;"  [(ngModel)]="proyect.descriptions" [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"></textarea>
          <label style="margin-top: -20px;" for="descriptions">Descripción</label>
        </div>
      </div>
    </div>



    <div class="item-container">
      <p-fileUpload [hidden]="loading" chooseLabel="Fotos proyectos" previewWidth="100" [files]="uploadedFiles"
        customUpload="true" (uploadHandler)="myUploader($event)" multiple="multiple" accept="image/*" [auto]="true"
        (onRemove)="onFileQueueRemove($event)">
      </p-fileUpload>
    </div>

    <div class="item-container" style="text-align: center;">
      <button class="btn" (click)="onAddProyect()">{{mode == 'edit'? 'Modificar' : 'Añadir'}}</button>
    </div>

  </div>
</div>
