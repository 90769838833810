import { Injectable } from '@angular/core';
import { GalleryService } from './gallery.service';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private gallerySrv: GalleryService, private confirmationSvc: ConfirmationService) { }

  async getInfoReferencesStorage() {
    const getImages = this.getDataProyects();

    const promises = getImages.map(author => this.gallerySrv.getRefFromPhotos(author.propietary, author.proyects));

    const result = await Promise.all(promises);

    await this.gallerySrv.setImagesByPropietary(result);
  }

  private getDataProyects(): { propietary: 'JORGE' | 'GABRIEL' | 'HA', proyects: string[] }[] {
    const jorge = ['arsenal', 'casa_abreu', 'casa_bancal', 'girasol', 'hotel_escala', 'parque_maspalomas', 'porcelanosa', 'roma']
    const gabriel = ['asieta', 'calp', 'casa_palmert', 'club_nautico', 'cst', 'iglesia_benahoare', 'oficina_turismo', 'polideportivo', 'vivienda_llorens', 'casa_yanes']
    const ha = ['bodega_tijarafe', 'casa_brezal', 'hotel_terapeutico', 'hsj', 'marquesina_HSJ', 'oficina_policia', 'parque_tecnologico', 'piscina_municipal', 'villa_levy', 'vivienda_andres', 'vivienda_lorenzo'];

    return [
      {
        propietary: 'JORGE',
        proyects: jorge
      },
      {
        propietary: 'GABRIEL',
        proyects: gabriel
      },
      {
        propietary: 'HA',
        proyects: ha
      }
    ]

  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }

}
