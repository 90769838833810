import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UserInterface } from 'src/app/schemas/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Route, Router } from '@angular/router';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  pass: string = "";

  errors: { code: string, message: string };

  user: UserInterface = {
    name: "",
    email: ""
  }

  showContent: Boolean = false;

  constructor(private authSvc: AuthService, private router: Router) {
    this.authSvc.$user.pipe(take(1), map(user => !!user)).subscribe(res => {
      if (res) {
        this.router.navigateByUrl('/admin/dashboard');
      } else {
        this.showContent = true;
      }
    })
   }


  ngOnInit(): void {
  }

  onLogin(user: UserInterface, pass: string) {
    console.log(user, pass);
    this.authSvc.login(user.email, pass)
      .then(response => {
        if (response) {
          this.router.navigateByUrl('admin/dashboard');
          return;
        }
      }).catch(err => {
        console.error(err);
        this.errors = {
          code: err.code,
          message: err.message
        }
      });

  }

}
