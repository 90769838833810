<div *ngIf="gallery" class="container">
  <div class="row">
    <div class="menu col-lg-4">
      <navbar></navbar>

      <h1 class="project-title no-mv">{{gallery.name}} <br *ngIf="gallery.location.includes(',')">{{gallery.location}}</h1>
      <span class="project-year no-mv">{{gallery.year}}</span>
      <div *ngIf="gallery.authors?.length > 0" style="width: 200px;" class="distance-top-40">
        <span  class="project-year no-mv">{{gallery.descriptions}}</span>
      </div>
      <div *ngIf="gallery.authors?.length > 0" class="distance-top-40">
        <span *ngFor="let author of gallery.authors" class="project-year no-mv">{{author}}</span>
      </div>
      <div *ngIf="gallery.awards?.length > 0">
        <span *ngFor="let award of gallery.awards" class="project-year no-mv distance-top-40">{{award}}</span>
      </div>
    </div>
    <div class="col-lg-8">
      <div id="carouselExampleControls" class="carousel " data-interval="false" data-ride="carousel">
        <div class="carousel-inner">
          <div *ngFor="let url of gallery.urlImgs, let i = index" class="carousel-item" [class.active]="i==0" >
            <img class="d-block w-75 big-image " loading="edge" [src]="url"
              [alt]="'slide ' + i">
          </div>
        </div>
        <div class="position-btn">
          <a class="" href="#carouselExampleControls" data-keyboard="true" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="" href="#carouselExampleControls" data-keyboard="true" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      <div class="mv-dsc mv">
        <h1 class="project-title mv">{{gallery.name}}.<br>{{gallery.location}}</h1>
        <span class="project-year mv">{{gallery.year}}</span>
        <div *ngIf="gallery.authors?.length > 0" class="distance-top-40">
          <span *ngFor="let author of gallery.authors" class="project-year no-mv">{{author}}</span>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>
