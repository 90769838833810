<div class="container">
  <div class="row">
    <div class="col-lg-4 menu">
      <navbar></navbar>
    </div>
    <div class="col-lg-8">
      <!-- <div style="display: flex; justify-content: flex-end; padding-right: 10px;">
        <div *ngIf="page !== 0" (click)="backPage(page)"><img class="control-pages" style="transform: rotate(180deg); margin-right: 20px;"
            src="/assets/icons/next.svg" alt="" srcset=""></div>
        <div *ngIf="page !== pagination.length - 1" (click)="nextPage(page)"><img class="control-pages" src="/assets/icons/next.svg" alt=""
            srcset=""></div>
      </div> -->
      <div class="grid--gallery">
        <div class="link-photo" *ngFor="let proyect of proyects, let idx = index"
          (click)="goToDetails(proyect.id)">
          <img class="img-gallery" [ngClass]="{'setGrayScale': proyect.isBlacked}" [src]="proyect.urlCover"
            loading="lazy" alt="imagen">
          <div class="name-photo">
            {{proyect.propietary == 'JORGE'? '(' +proyect.orderPropietary + ')' + ' · ' + proyect.coverName  : '[' +proyect.orderPropietary + ']' + ' · ' + proyect.coverName }}
          </div>
        </div>
        <div *ngFor="let refill of emptySquares" class="re-fill"></div>
      </div>

    </div>
  </div>
</div>
