<div class="container">
  <div class="row">
          <div class="menu col-lg-4">
                  <ul class="nav-bar">
                          <navbar [hide]="true"></navbar>
                          <li class="info-estudio item-info no-mv">·Calle Virgen de La Luz 47.
                                  38700 Santa Cruz de La Palma. <br>
                                  <span class="separate no-mv">Santa Cruz de Tenerife. Islas Canarias.
                                          España.</span>
                          </li>
                          <li class="info-estudio item-info no-mv">·Tfno | Fax: +0034 922 416 410</li>
                          <li class="info-estudio item-info no-mv">·mail: gh@henriquezarquitectos.com
                          <li class="info-estudio item-info no-mv">·mail: jh@henriquezarquitectos.com
                          </li>
                           <li class="info-estudio no-mv"><img class="img-estudio"
                                          src="../../assets/img/estudio/Ortofoto_La_Palma.jpg" alt=""> </li>

                  </ul>
          </div>
          <div class="container-img col-lg-8">
                  <ul class="">
                          <li class="info-estudio item-info mv">·Calle Virgen de La Luz 47.
                                  <br>
                                  38700 Santa Cruz de La Palma. <br>
                                  <span class="separate mv">Santa Cruz de Tenerife. Islas Canarias.
                                          España.</span>
                          </li>
                          <li class="info-estudio item-info mv">·Tfno | Fax: +0034 922 416 410</li>
                          <li class="info-estudio item-info mv">·mail: gh@henriquezarquitectos.com
                          <li class="info-estudio item-info mv">·mail: jh@henriquezarquitectos.com
                          </li>
                          <li class="info-estudio mv"><img class="img-estudio"
                                          src="../../assets/img/estudio/Ortofoto_La_Palma.jpg" alt="">
                          </li>

                  </ul>
                  <img class="estudio-location" [src]="urlStudioImg" alt="">
          </div>
  </div>
</div>
