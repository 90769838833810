export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyAk7OCYXEdbCLtuDhpfuAB128J_h2VnDys",
    authDomain: "arquitect-c75c2.firebaseapp.com",
    databaseURL: "https://arquitect-c75c2.firebaseio.com",
    projectId: "arquitect-c75c2",
    storageBucket: "arquitect-c75c2.appspot.com",
    messagingSenderId: "382177183347",
    appId: "1:382177183347:web:62922f6e8395015d200c06",
    measurementId: "G-90PJ0QWKRF"
  }
};
