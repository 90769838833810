import { Component, OnInit, Input } from '@angular/core';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() hide: boolean = false;
  mode: 'es' | 'en' = 'es'

  constructor(private galleryService: GalleryService) { }

  ngOnInit(): void {
    this.mode = this.galleryService.getMode();
  }

}
