import { Component, OnInit } from '@angular/core';
import { Gallery } from 'src/app/schemas/models/gallery.model';
import { GalleryService } from 'src/app/services/gallery.service';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  originalProyects: Gallery[] = [];
  proyects: Gallery[] = [];
  arrayConfirmationLoad: Boolean[] = []
  mode: 'main' | 'portrait' | 'studio' = 'main';
  imgPortrait: File[] = [];
  imgStudio: File[] = [];

  urlPortrait: string = "";
  urlStudio: string = "";
  urlOriginalPortrait: string = "";
  urlOriginalStudio: string = "";
  searching: string = "";
  loading: boolean = false;


  constructor(private gallerySrv: GalleryService, private router: Router, private utilsSvc: UtilsService) { }

  async ngOnInit(): Promise<void> {
    this.proyects = await this.gallerySrv.getAllProyects();
    await this.getPortraitInCase();
    await this.getStudioImgInCase();
    this.proyects = this.proyects.map(proyect => {
      return {
        ...proyect,
        searchBy: this.getSearchStringByProyect(proyect)
      }
    })
    this.originalProyects = this.proyects;
  }

  getSearchStringByProyect(proyect): string {
    return `${proyect.name.includes(" ") ? proyect.name.replace('.', '').replace(',', '').split(" ").join("_") : proyect.name}_${proyect.coverName.includes(" ") ? proyect.coverName.replace('.', '').replace(',', '').split(" ").join("_") : proyect.coverName}_${proyect.location.includes(" ") ? proyect.location.replace('.', '').replace(',', '').split(" ").join('_') : proyect.location.replace('.', '').replace(',', '')}`.toLowerCase()
  }

  async getPortraitInCase() {
    this.urlPortrait = await this.gallerySrv.getImgLanding('portrait');
    this.urlOriginalPortrait = this.urlPortrait;
    if (this.urlPortrait) {
      const blobUrlCover: Blob = await this.gallerySrv.downloadImgFromUrl(this.urlPortrait);
      const file: File = new File([blobUrlCover], `${this.urlPortrait}.jpg`, { type: 'image/*', lastModified: Date.now() })
      this.imgPortrait = [file];
    }
  }

  async getStudioImgInCase() {
    this.urlStudio = await this.gallerySrv.getImgLanding('studio');
    this.urlOriginalStudio = this.urlStudio;
    if (this.urlStudio) {
      const blobUrlCover: Blob = await this.gallerySrv.downloadImgFromUrl(this.urlStudio);
      const file: File = new File([blobUrlCover], `${this.urlStudio}.jpg`, { type: 'image/*', lastModified: Date.now() })
      this.imgStudio = [file];
    }
  }

  goToDetails(id: string) {
    this.router.navigateByUrl(`admin/dashboard/edit/${id}`);
  }

  onBasicUpload(event, page: 'portrait' |'studio') {
    console.log(event)
    if (page == 'portrait') {
      this.imgPortrait = event.files;
      this.urlPortrait = ""
    } else {
      this.imgStudio = event.files;
      this.urlStudio= ""
    }
  }

  removeCover($event, page) {
    if (page == 'portrait') {
      this.imgPortrait = [];
    } else {
      this.imgStudio = [];
    }
  }

  async onUpdateImgsLanding(imgArray: File[] , page: 'portrait' | 'studio') {
    this.loading = true;

    let url = ""
    if (imgArray.length) {
      url = await this.gallerySrv.uploadImg(imgArray[0], page, Date.now() + Math.random());
    }

    if (page == "portrait") {
      this.urlPortrait = url;
      this.urlOriginalPortrait = this.urlPortrait;
    } else {
      this.urlStudio = url;
      this.urlOriginalStudio = this.urlPortrait;
    }


    if(url) {
      await this.gallerySrv.setImgLanding(url, page)
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se ha guardado correctamente',
        showConfirmButton: false,
        timer: 1500
      })
      this.loading = false;
    } else {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ha ocurrido un error',
          showConfirmButton: false,
          timer: 1500
        })
        this.loading = false;
    }
  }

  filteredData(searching: string) {
    console.log(searching);

    if (searching) {
      this.proyects = this.originalProyects.filter(p => p.searchBy.includes(searching))
      console.log(this.proyects)
    } else {
      this.proyects = this.originalProyects;
    }
  }
}
