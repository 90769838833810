<p-blockUI [blocked]="loading" [target]="pnl">
  <div style="position:absolute; width: 110px; left: 47.5%; top:50%">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-blockUI>
<div #pnl class="container">
  <nav style="display: flex; justify-content: center; margin-bottom: 20px; z-index: 233232;">
    <p-selectButton [options]="[{label: 'Galeria', value: 'main'}, {label: 'Portada', value: 'portrait'},{label: 'Estudio', value: 'studio'}]"
      [(ngModel)]="mode"></p-selectButton>
  </nav>
  <div *ngIf="mode == 'main'">
    <div class="offset-lg-2 col-lg-8">
      <input class="search" (ngModelChange)="filteredData(searching)" [(ngModel)]="searching" placeholder="Buscar" type="text">
    </div>
    <div class="row" *ngIf="proyects">
      <div class="col-lg-12">
        <div class="grid--gallery">
          <div class="link-photo" *ngFor="let proyect of proyects, let idx = index" (click)="goToDetails(proyect.id)">
            <img class="img-gallery" [ngClass]="{'setGrayScale': proyect.isBlacked}" [src]="proyect.urlCover"
              alt="imagen" loading="lazy">
            <div class="name-photo">
              {{proyect.propietary == 'JORGE'? '(' +proyect.orderPropietary + ')' + ' · ' + proyect.coverName  : '[' +proyect.orderPropietary + ']' + ' · ' + proyect.coverName }}
            </div>
          </div>
          <a *ngIf="!searching" style="text-decoration: none;" routerLink="/admin/dashboard/add" class="re-fill">
            <p>+</p>
          </a>
        </div>
      </div>
    </div>
    <div class="no-result" *ngIf="!proyects.length && searching">
      <h1>No hay resultados</h1>
    </div>
  </div>

  <div #pnl class="container-portrait" *ngIf="mode =='portrait'">
    <div class="row">
      <p-fileUpload [hidden]="loading" [files]="imgPortrait" previewWidth="100" chooseLabel="Portada" [auto]="true" accept="image/*"
        (uploadHandler)="onBasicUpload($event,mode)" customUpload="true" (onRemove)="removeCover($event, mode)">
      </p-fileUpload>
    </div>
    <div style="text-align: center;">
      <button [disabled]="urlPortrait === urlOriginalPortrait" class="btn"
        (click)="onUpdateImgsLanding(imgPortrait, mode)">Actualizar Portada</button>
    </div>
  </div>


  <div #pnl class="container-portrait" *ngIf="mode =='studio'">
    <div class="row">
      <p-fileUpload [hidden]="loading" [files]="imgStudio" previewWidth="100" chooseLabel="Estudio" [auto]="true" accept="image/*"
        (uploadHandler)="onBasicUpload($event, mode)" customUpload="true" (onRemove)="removeCover($event, mode)">
      </p-fileUpload>
    </div>
    <div style="text-align: center;">
      <button  class="btn" [disabled]="urlOriginalStudio == urlStudio"
        (click)="onUpdateImgsLanding(imgStudio, mode)">Actualizar Estudio</button>
    </div>
  </div>

</div>
