import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import {SelectButtonModule} from 'primeng/selectbutton';

import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {ChipsModule} from 'primeng/chips';
import {FileUploadModule} from 'primeng/fileupload';
import {InputNumberModule} from 'primeng/inputnumber';
import {ButtonModule} from 'primeng/button';
import {PasswordModule} from 'primeng/password';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {InputSwitchModule} from 'primeng/inputswitch';
import {BlockUIModule} from 'primeng/blockui';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import { AngularFireModule } from '@angular/fire';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask
} from "@angular/fire/storage";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './pages/intro/intro.component';
import { MasterGalleryComponent } from './pages/master-gallery/master-gallery.component';
import { DetailsGalleryComponent } from './pages/details-gallery/details-gallery.component';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StudioComponent } from './pages/studio/studio.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardDetailsComponent } from './pages/dashboard-details/dashboard-details.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    MasterGalleryComponent,
    DetailsGalleryComponent,
    NavbarComponent,
    StudioComponent,
    LoginComponent,
    DashboardComponent,
    DashboardDetailsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    ConfirmDialogModule,
    BlockUIModule,
    PasswordModule,
    InputNumberModule,
    SelectButtonModule,
    InputSwitchModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    FileUploadModule,
    AngularFireStorageModule,
    ChipsModule,
    SweetAlert2Module.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [ConfirmationService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
