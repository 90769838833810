<div class="container">
  <div  class="row centered">
    <div class="col-md-8 wrap-center background-intro">
      <h1 class="title">Henríquez Arquitectos</h1>
      <div class="btn-language--intro">
        <a class="link-language" (click)="goToGallery('es')">Español</a>
        <a class="br-left link-language" (click)="goToGallery('en')">English</a>
      </div>
    </div>
    <div class="col-md-4 contain-background">
      <img class="img-background" loading="lazy" [src]="portraitImgUrl" alt="image">
    </div>
  </div>
</div>
